<template>
  <div>
    <p class="text-center text-14 text-bold p-b-5">客户分析</p>

    <Row :gutter="16" class="analysis-border-bottom">
      <i-col span="12" class="analysis-border-right">
        <p class="text-14 text-bold">品牌池</p>
        <Table class="analysisTable" size="small" :columns="listAdvertiserManageColumn" :data="listAdvertiserManageData" :row-class-name="rowClassName1"
          @on-row-click="handleClickRow1"></Table>

        <p class="text-14 text-bold m-t-5">品牌明细</p>
        <Table class="analysisTable" size="small" :columns="listAdvertiserIndustryColumn" :data="listAdvertiserIndustryData" :row-class-name="rowClassName2"
          @on-row-click="handleClickRow2"></Table>
        <div v-show="showArray.length>currentPageSize" class="paging_style">
          <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
        </div>
      </i-col>
      <i-col span="12">
        <p class="text-14 text-bold">客户</p>
        <Table class="analysisTable" size="small" :columns="listCustomerColumn" :data="listCustomerData" :row-class-name="rowClassName3"
          @on-row-click="handleClickRow3"></Table>
        <div v-show="showArray2.length>currentPageSize2" class="paging_style">
          <Page size="small" :total="showArray2.length" :page-size="currentPageSize2" :current="currentPageNumber2" @on-change="handlePageChange2"></Page>
        </div>

        <p class="text-14 text-bold m-t-5">客户应回款逾期账龄分布</p>
        <Row  class="table-title text-right" :gutter="8">
          <i-col span="8" class="text-left">账龄</i-col>
          <i-col span="8">金额(元)</i-col>
          <i-col span="8">占比</i-col>
        </Row>
        <Row v-for="(item,index) in listOverdueDurationData" :key="'2_' + index" :gutter="8" class="p-t-5 text-right">
          <i-col span="8" class="text-left">{{item.name}}</i-col>
          <i-col span="8">{{formatNumber(item.value)}}</i-col>
          <i-col span="8">{{item.rate}}%</i-col>
        </Row>
        <div v-show="showArray3.length>currentPageSize3" class="paging_style">
          <Page size="small" :total="showArray3.length" :page-size="currentPageSize3" :current="currentPageNumber3" @on-change="handlePageChange3"></Page>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toNumber } from '@/utils/wnumb_own'
import {
  listPlatformBrandManage, listPlatformBrandBySignType,
  listAdvertiserByBrand, listOverdueDuration
} from '@/api/dw/manage'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      listAdvertiserManageData: [],
      listAdvertiserManageColumn: [
        { title: '类型', key: 'name' },
        { title: '数量', key: 'number' },
        {
          title: '占比',
          render: (h, params) => {
            return h('span', params.row.rate + ' %')
          }
        },
        {
          title: '签约额(万元)',
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoneyByThousand(params.row.value))
          }
        },
        {
          title: '数量涨幅',
          align: 'right',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: this.getChainRateColor(params.row.numberChainRate)
              }
            }, params.row.numberChainRate + '%')
          }
        },
        {
          title: '金额涨幅',
          align: 'right',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: this.getChainRateColor(params.row.valueChainRate)
              }
            }, params.row.valueChainRate + '%')
          }
        }
      ],
      chooseAdvertiserId: null,

      listAdvertiserIndustryData: [],
      listAdvertiserIndustryColumn: [
        { title: '品牌名称', key: 'brandName' },
        { title: '所属行业', key: 'industryName' },
        {
          title: '占比',
          render: (h, params) => {
            return h('span', params.row.rate + ' %')
          }
        },
        {
          title: '签约额(万元)',
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoneyByThousand(params.row.amount))
          }
        },
        {
          title: '金额涨幅',
          align: 'right',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: this.getChainRateColor(params.row.upRate)
              }
            }, params.row.upRate + '%')
          }
        }
      ],
      chooseAdvertiserIndustryId: null,
      showArray: [],
      currentPageSize: 4,
      currentPageNumber: 1,

      listCustomerData: [],
      listCustomerColumn: [
        {
          title: '名称',
          minWidth: 100,
          render: (h, params) => {
            return h('Tooltip', {
              props: {
                content: params.row.advertiserName,
                transfer: true,
                'max-width': '200px'
              }
            }, params.row.advertiserName.length > 10 ? params.row.advertiserName.substr(0, 10) + '..' : params.row.advertiserName)
          }
        },
        {
          title: '签约额(万元)',
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoneyByThousand(params.row.amount))
          }
        },
        {
          title: '占比',
          align: 'right',
          render: (h, params) => {
            return h('span', params.row.amountRate + ' %')
          }
        },
        {
          title: '折扣',
          align: 'right',
          render: (h, params) => {
            return h('span', params.row.discountRate + ' %')
          }
        },
        {
          title: '逾期(万元)',
          align: 'right',
          render: (h, params) => {
            return h('span', this.formatMoneyByThousand(params.row.overdueAmount))
          }
        },
        {
          title: '履约偏差率',
          align: 'right',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: this.getChainRateColor(params.row.onRate)
              }
            }, params.row.onRate + '%')
          }
        }
      ],
      chooseCustomerId: null,
      showArray2: [],
      currentPageSize2: 4,
      currentPageNumber2: 1,

      listOverdueDurationData: [],
      showArray3: [],
      currentPageSize3: 4,
      currentPageNumber3: 1
    }
  },
  created () {
    this.loadAdvertiserData()
    this.loadIndustryData()
    this.loadCustomerData()
    this.loadOverdueDurationData()
  },
  methods: {
    loadAdvertiserData () { // 客户池
      this.chooseAdvertiserId = null
      const queryModel = {
        chainEndDate: this.compareSchedule.endDate,
        chainStartDate: this.compareSchedule.startDate,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate
      }
      listPlatformBrandManage(queryModel).then(res => {
        this.listAdvertiserManageData = res
      })
    },
    loadIndustryData () {
      this.chooseAdvertiserIndustryId = null
      // const industryType = (this.$store.getters.token.publisherSetting.financialBoard === 2)
      //   ? 'inter_industry' : 'industry'

      const queryModel = {
        chainEndDate: this.compareSchedule.endDate,
        chainStartDate: this.compareSchedule.startDate,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        signType: this.chooseAdvertiserId
      }
      listPlatformBrandBySignType(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    loadCustomerData () { // 客户
      this.chooseCustomerId = null
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        signType: this.chooseAdvertiserId,
        platformBrandId: this.chooseAdvertiserIndustryId
      }
      listAdvertiserByBrand(queryModel).then(res => {
        this.showArray2 = res
        this.handlePageChange2(1)
      })
    },
    loadOverdueDurationData () { // 账龄数据
      const queryModel = {
        publisherId: this.publisherId,
        advertiserId: this.chooseCustomerId
      }
      listOverdueDuration(queryModel).then(res => {
        this.showArray3 = res
        this.handlePageChange3(1)
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.listAdvertiserIndustryData = newArray
    },
    handlePageChange2 (page) {
      this.currentPageNumber2 = page
      const newArray = []
      for (let index = this.currentPageSize2 * (page - 1); index < this.currentPageSize2 * page; index++) {
        if (index < this.showArray2.length) {
          newArray.push(this.showArray2[index])
        } else {
          break
        }
      }

      this.listCustomerData = newArray
    },
    handlePageChange3 (page) {
      this.currentPageNumber3 = page
      const newArray = []
      for (let index = this.currentPageSize3 * (page - 1); index < this.currentPageSize3 * page; index++) {
        if (index < this.showArray3.length) {
          newArray.push(this.showArray3[index])
        } else {
          break
        }
      }

      this.listOverdueDurationData = newArray
    },
    rowClassName1 (row, index) {
      return row.id === this.chooseAdvertiserId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    rowClassName2 (row, index) {
      return row.brandId === this.chooseAdvertiserIndustryId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    rowClassName3 (row, index) {
      return row.advertiserId === this.chooseCustomerId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow1 (params, index) { // 点击客户池
      this.chooseAdvertiserId = (this.chooseAdvertiserId === params.id) ? null : params.id
      this.loadIndustryData()
      this.loadCustomerData()
    },
    handleClickRow2 (params, index) { // 点击行业
      this.chooseAdvertiserIndustryId = (this.chooseAdvertiserIndustryId === params.brandId) ? null : params.brandId
      this.loadCustomerData()
    },
    handleClickRow3 (params, index) { // 点击客户
      this.chooseCustomerId = (this.chooseCustomerId === params.advertiserId) ? null : params.advertiserId
      this.loadOverdueDurationData()
    },
    formatMoneyByThousand (number) {
      return (number * 0.0001).toFixed(2)
    },
    getChainRateColor (number) {
      let color = 'text-red'
      if (number < 0) {
        color = 'text-green'
      } else if (number === 0) {
        color = 'text-yellow'
      }
      return color
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    compareSchedule () {
      return this.$store.state.situationWeb.compareSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadAdvertiserData()
        this.loadIndustryData()
        this.loadCustomerData()
        this.loadOverdueDurationData()
      }
    },
    compareSchedule: {
      deep: true,
      handler (newValue) {
        this.loadAdvertiserData()
        this.loadIndustryData()
      }
    }
  }
}
</script>

<style>
.analysisTable .ivu-table-cell{
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
